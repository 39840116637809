import React from 'react';

const WebGlSupportWarning = () => {
  return (
    <div className='bg-ih-yellow-5 border-l-4 border-ih-yellow-40'>
      <div className='flex flex-col gap-3 text-sm text-ih-orange p-4'>
        <p>
          L'affichage de la carte nécessite WebGL.
          Malheureusement, WebGL n'est pas disponible sur votre navigateur.
          Pour accéder à toutes les fonctionnalités et ainsi visualiser la carte, veuillez :
        </p>
        <ul className='list-disc ml-4'>
          <li>Utiliser un navigateur récent (Chrome, Firefox, Edge)</li>
          <li>Vérifier que votre carte graphique est à jour</li>
          <li>Activer l'accélération matérielle dans les paramètres de votre navigateur</li>
        </ul>
      </div>
    </div>
  );
};

export default WebGlSupportWarning;
