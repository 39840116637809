// parisRegion and noExclusivity are booleans, price is an Integer
export function agencyFees(parisRegion, price, noExclusivity) {
  let feesAmount = 0;

  feesAmount = parisRegion ? parisFees(price) : regionFees(price);
  if (noExclusivity)  feesAmount += calculateNoExclusivityAddFees(price, parisRegion);

  return feesAmount;
}

function parisFees(price) {
  if (price <= 100000) { return 5900 }
  else if (price <= 200000) { return 6900 }
  else if (price <= 300000) { return 7900 }
  else if (price <= 400000) { return 8900 }
  else if (price <= 500000) { return 9900 }
  else if (price <= 600000) { return 12900 }
  else if (price <= 700000) { return 15900 }
  else if (price <= 800000) { return 18900 }
  else { return price * 0.025 }
}

function regionFees(price) {
  if (price <= 100000) { return 5900 }
  else if (price <= 300000) { return 6900 }
  else if (price <= 400000) { return 8900 }
  else if (price <= 500000) { return 9900 }
  else { return price * 0.025 }
}

function calculateNoExclusivityAddFees(price, parisRegion) {
  if (price <= process.env.PERCENTAGE_TRESHOLD_REGIONS) { return 2000 }
  else if (parisRegion && price <= process.env.PERCENTAGE_TRESHOLD_PARIS) { return 4000 }
  else { return 0.005 * price }
}
