import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { checkWebGLSupport } from '../../../../utils/webgl';
import WebGlSupportWarning from '../../../../components/WebGlSupportWarning';

import TitleBanner from '../../components/TitleBanner';
import Characteristics from './components/Characteristics';
import StrenghtsAndWeaknesses from './components/StrenghtsAndWeaknesses';
import Sector from './components/Sector';

import { Map } from 'hosman-material';

const Property = ({ estimationFolder }) => {
  const hasWebGLSupport = useMemo(() => checkWebGLSupport(), []);

  const { strengths, weaknesses, property_picture_url, quarter_description, quarter_map_display,
          sale: { property, quarter_id,
            expert: { user }
          }
        } = estimationFolder

  return (
    <div data-testid='Property'>
      <TitleBanner number='01' title='Votre bien' />

      <div className='flex flex-col gap-12 text-ih-indigo'>
        <div className='bg-hosman-gradient-1 py-10'>
          <div className='flex flex-col md:flex-row gap-6 md:gap-20 items-center px-2 md:px-20 mx-auto'>
            <div className='md:w-full flex flex-col gap-8'>
              <p className='h2 text-center'>Les <span className='text-ih-blue'>caractéristiques</span> du bien</p>
              <div className='w-2/3 md:w-full mx-auto'>
                <Characteristics property={estimationFolder.sale.property} />
              </div>
            </div>

            <div className='w-full'>
              {(process.env.NODE_ENV !== 'test' && hasWebGLSupport) &&
                <Map
                  accessToken={process.env.MAPBOX_PUBLIC_KEY}
                  style={process.env.MAPBOX_STYLE_ADVERT}
                  center={{ lat: property.lat, lng: property.lng }}
                  zoom={11}
                  marker
                  fixed
                  height='h-72'
                />
              }

              {!hasWebGLSupport && <WebGlSupportWarning />}
            </div>
          </div>
        </div>

        <StrenghtsAndWeaknesses
          strengths={strengths}
          weaknesses={weaknesses}
          propertyPictureUrl={property_picture_url}
          avatarPictureUrl={user.profile_pic_url}
        />

        <div className='pb-20'>
          <Sector
            property={property}
            quarterId={quarter_id}
            quarterDescription={quarter_description}
            quarterMapDisplay={quarter_map_display}
            hasWebGLSupport={hasWebGLSupport}
          />
        </div>
      </div>
    </div>
  );
}

export default Property;

Property.propTypes = {
  estimationFolder: PropTypes.object.isRequired,
};
