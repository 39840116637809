export const checkWebGLSupport = () => {
  try {
    const canvas = document.createElement('canvas');
    const gl = canvas.getContext('webgl') ||
              canvas.getContext('experimental-webgl');
    return !!gl;
  } catch (e) {
    return false;
  }
};
