import React from 'react';
import PropTypes from 'prop-types';
import { generateLink } from '../../../shared/utils/railsRoutes';
import { pricePerMeter } from '../../../shared/utils/helpers/hosmanVars';

import Characteristics from './Characteristics';

import { Label, Avatar, Carousel, HosmanLogo } from 'hosman-material';

const PropertyCard = ({ property, stateLabel, centerLabel, hosmanLabel, avatar, propertyInfos = true, premium = false, price, clickable = true, areaPrice = false }) => {
  const handleClick = () => {
    if ((!property.slug && !property.url) || !clickable) return;

    window.open(property.url || generateLink(RailsRoutes.adverts_property_path(property.slug)))
  }

  return (
    <div onClick={() => handleClick()} className={`flex flex-col gap-3 border ${clickable ? 'hover:border-ih-purple-30 cursor-pointer' : ''} rounded-3xl shadow-ih-1 bg-white`} data-testid='PropertyCard'>
      <div className='relative w-full'>
        <Carousel images={[property.pictures[0]]} />

        <div className='absolute top-4 right-4 z-10'>
          {stateLabel && stateLabel}
        </div>

        {hosmanLabel &&
          <div className='flex absolute top-4 z-10 right-4'>
            <Label color='bg-ih-blue'><HosmanLogo width={85} rgbColor='#ffffff' /></Label>
          </div>
        }

        <div className='absolute -bottom-6 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 w-full text-center'>
          {centerLabel && centerLabel}
        </div>

        {premium &&
          <div className='absolute top-4 z-10 left-4'>
            <Label color='bg-ih-yellow-70'><span className='text-ih-indigo'>Premium</span></Label>
          </div>
        }

        <div className='absolute -bottom-6 left-5 z-10'>
          {avatar && <Avatar border imageSrc={avatar} />}
        </div>
      </div>
      <div className='flex flex-col justify-between p-5 text-ih-neutral w-full'>
        {propertyInfos &&
          <div>
            <p className='h4'>{property.street_number} {property.street_name}</p>
            <div className='mt-6'>
              <Characteristics property={property} short />
            </div>
          </div>
        }
      </div>
      <div className='text-center bg-ih-purple-10 p-3 rounded-b-2xl transition-all w-full'>
        <p className='h4 text-ih-blue'>{price.toLocaleString()} €</p>
        {areaPrice && <p className='text-sm text-ih-indigo'>{pricePerMeter(price, property)}</p>}
      </div>
    </div>
  )
}

export default PropertyCard;

// PropTypes
PropertyCard.propTypes = {
  property: PropTypes.object.isRequired,
  stateLabel: PropTypes.node,
  centerLabel: PropTypes.node,
  avatar: PropTypes.string,
  propertyInfos: PropTypes.bool,
  premium: PropTypes.bool,
  price: PropTypes.number,
  clickable: PropTypes.bool,
  areaPrice: PropTypes.bool
};
